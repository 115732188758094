import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";

import * as styles from "./landing.module.scss";

const Landing = () => {
  return (
    <section className={styles.landing}>
      <div className={styles.background}>
        <StaticImage src="../../images/landingImage1.png"
                     alt="ilustrace v pozadí"
                     objectFit={"cover"}
        />
        <StaticImage src="../../images/landingImage2.png"
                     alt="ilustrace v pozadí"
                     className={styles.image2}
                     objectFit={"cover"}
        />
      </div>

      <div className={styles.text}>
        <h1>
          <b>Pomáháme tam,</b><br/>
          kde na rozhodnutí<br />
          <b>záleží</b>
        </h1>
      </div>
    </section>
  );
};

export default Landing;
