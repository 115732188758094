import * as React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

import * as styles from "./realisation-modal-content.module.scss";

const ModalContent = ({title, description, images}) => {
  return (
    <div className={styles.container}>
      <div>
        <h2>{title}</h2>
        {description.split("\n").map(text => <p>{text}</p>)}
      </div>
      {images !== null && images.length > 0 && (
        <div className={styles.images}>
          {images?.map(image => (
            <GatsbyImage
              alt="obrázek realizace"
              image={getImage(image.file)}
              key={image.path}
              className={styles.image}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ModalContent;
