import * as React from "react";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import {useEffect, useRef, useState} from "react";
import {useForceRender} from "../../hooks/forceRender";
import Modal from "react-modal";

import * as styles from "./slideshow.module.scss";
import ModalContent from "../realisation-modal-content/realisation-modal-content";

Modal.setAppElement(`#___gatsby`);

const Slideshow = ({realisations}) => {

  const paginationRef = useRef(null);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);

  const forceRender = useForceRender();

  const [modalOpen, setModalOpen] = useState(false);
  const [displayedRealisation, setDisplayedRealisation] = useState(realisations[0]);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = (realisation) => {
    setDisplayedRealisation(realisation);
    setModalOpen(true);
  }

  useEffect(() => {
    if (modalOpen) {
      document?.body?.classList?.add('no-scroll');
    } else {
      document?.body?.classList?.remove('no-scroll');
    }
  }, [modalOpen]);

  return (
    <div className={styles.container}>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Realizace"
      >
        <div className={styles.buttonContainer}>
          <button
            onClick={closeModal}
            className={styles.closeButton}
          >
            <i className="far fa-times" />
          </button>
        </div>
        <ModalContent
          title={displayedRealisation?.title}
          description={displayedRealisation?.description}
          images={displayedRealisation?.images}
        />
      </Modal>

      <StaticImage src="../../images/building_sky.jpg"
                   alt="ilustrace v pozadí"
                   className={styles.backgroundImage}
                   objectFit={"cover"}
                   layout="fullWidth"
      />

      <section className={styles.presentation} data-navigation-id="realizace">
        <h2>Realizace</h2>
        <div className={styles.content}>
          <button
            className={classNames({
              [styles.navigationPrev]: true,
              [styles.navigation]: true,
            })}
            ref={prevBtnRef}
          >
            <i className="fal fa-chevron-left"/>
          </button>
          <div className={styles.slideshowWrapper}>

            <Swiper
              navigation={{
                prevEl: prevBtnRef.current,
                nextEl: nextBtnRef.current
              }}
              pagination={{
                el: paginationRef.current,
                clickable: true,
              }}
              spaceBetween={10}
              slidesPerView={1}
              onInit={() => {
                // Needed to correctly link navigation refs
                forceRender();
              }}
              breakpoints={{
                // when window width is >= 768px
                768: {
                  spaceBetween: 20,
                  slidesPerView: 2,
                },
              }}
              className={styles.swiper}
            >
              {/* SLIDES */}
              {realisations.map(item => (
                <SwiperSlide key={item._id}>
                  <div className={styles.slide}>
                    <div className={styles.overlay} onClick={() => openModal(item)}>
                      <div className={styles.overlayContent}>
                        <div className={styles.overlayBackground} />
                        <div className={styles.overlayText}>
                          <div>
                            <h3>{item.title}</h3>
                            {item.description.split("\n").map(text => <p>{text}</p>)}
                          </div>
                          <small>detail</small>
                        </div>
                      </div>
                    </div>
                    <GatsbyImage alt="realizace"
                                 image={getImage(item.images[0].file)}
                                 className={styles.slideImage}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <button
            className={classNames({
              [styles.navigationNext]: true,
              [styles.navigation]: true,
            })}
            ref={nextBtnRef}
          >
            <i className="fal fa-chevron-right"/>
          </button>

          <div className={styles.pagination} ref={paginationRef} />
        </div>
      </section>
    </div>
  );
};

export default Slideshow;
