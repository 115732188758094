import * as React from "react"
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import Landing from "../components/landing/landing";
import Slideshow from "../components/slideshow/slideshow";

import * as styles from "../styles/index.module.scss";
import {graphql} from "gatsby";

const HomePage = ({data}) => {

  return (
    <Layout light={true}>
      <Seo />

      {/* LANDING SECTION */}
      <Landing />

      {/* INTRO SECTION */}
      <section className={styles.intro}>
        <StaticImage src="../images/introIcons.png"
                     alt="ilustrace"
                     className={styles.illustration}
                     objectFit={"contain"}
        />
        <StaticImage src="../images/logo_black.png"
                     alt="logo"
                     className={styles.logoWrapper}
        />
        <h3>je jediný plně digitalizovaný</h3>
        <p>stavební inženýring, který efektivně řeší <br /> proces od projektu ke stavbě</p>
      </section>

      {/* REALIZACE */}
      <Slideshow realisations={data.api.realisations} />

    </Layout>
  )
}

export const query = graphql`
    query {
        api {
            realisations:realisationsCollection(sort: {_o: 1}) {
                _id
                title
                description
                images {
                    path
                    file {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    }
`;

export default HomePage
